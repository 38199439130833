.Footer {
  width: 100%;
  line-height: 2.2rem;
  margin-top: 5rem;
  padding: 0 10rem;

  background-color: #444444;
  color: #fefefe;

  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 600px) {
  .Footer {
    padding: 0 5rem;
  }
}

.FooterContent {
  font-size: 1.6rem;
  margin-top: 3rem;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
}

.FooterColumn {
  min-width: 10rem;
  margin-bottom: 3rem;
  margin-right: 1rem;

  display: flex;
  flex-direction: column;
  flex: 1;
}

.FooterColumnContact {
  flex: 2;
}

.FooterColumnContact a {
  overflow-wrap: break-word;
}

.FooterLine {
  margin-bottom: 2rem;
  width: 100%;
  height: 1px;
  background-color: #e5e5e5;
}

.FooterCopyright {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
}

.FooterCopyright a {
  color: inherit;
  text-decoration: none;
  margin-left: 2px;
}

.SocialIcons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.SocialIcons > a {
  font-size: 3rem;
  color: #ffffff;
  margin-right: 5px;
}

.LanguageSelection {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.LanguageSelection > img {
  width: 3rem;
  height: 2rem;
  margin: 5px;
  margin-top: 1rem;
  cursor: pointer;
}
