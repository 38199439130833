.TopMenu {
  margin: 4rem auto 0 30%;
}

.TopMenuContent {
  display: flex;
  flex-direction: row;
}

.TopMenuContent a {
  font-size: 2rem;
  padding: 0 1rem;
  text-transform: uppercase;
}

.TopMenuContent a:hover {
  color: #f0273a;
}

.ActiveNav {
  color: #f0273a;
}
