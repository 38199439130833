.Language {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.1rem;
  text-transform: uppercase;
}

.Language img {
  width: 2rem;
  height: 1.3rem;
  margin-left: 4px;
  cursor: pointer;
}
