.MainContainer {
  margin-top: 11rem;
  min-height: 50vh;
}

@media only screen and (max-width: 1000px) {
  .MainContainer {
    margin-top: 7rem;
  }
}

@media only screen and (max-width: 600px) {
  .MainContainer {
    margin-top: 6rem;
  }
}
