.HomePhoto {
  margin-top: 0;

  position: relative;
  height: 46rem;
  width: 100%;

  background: url("/public/images/vanmieu-2.jpg") no-repeat center;
  background-size: cover;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .HomePhoto {
    height: 18rem;
  }
}

.HomePhotoScrolling {
  display: flex;
  justify-content: center;
  align-items: center;
}

.HomePhotoScrolling a {
  color: inherit;
  text-decoration: none;
}

.ScrollingIcon {
  margin-bottom: -3rem;
  width: 10rem;
}
