.LogoYear {
  font-size: 1rem;
  position: absolute;
  top: 5.5rem;
  right: 1rem;
}

@media only screen and (max-width: 1000px) {
  .LogoYear {
    top: -1.7rem;
    right: 0;
  }
}
