.Header {
  color: #4e4d4e;
  background-color: #ffffff;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  height: 11rem;
  width: 100%;

  display: flex;
  justify-content: flex-end;
}

.HeaderMobile {
  height: 7rem;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.MobileMenu {
  font-size: 4rem;
  padding: 0 2rem;
  height: 100%;
  color: #000000;
  cursor: pointer;
  user-select: none;
  z-index: 1000;
  transition: all 0.3s ease-in-out;

  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .HeaderMobile {
    height: 6rem;
  }
}
