.ModalBackground {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(105, 99, 99, 0.8);
  z-index: 2000;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}

.VisibleModalBackground {
  opacity: 1;
  visibility: visible;
}
