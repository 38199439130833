.FacebookPosts {
  max-height: 60rem;
  overflow: auto;
}

.FacebookPosts div {
  margin-bottom: 1rem;
}

@media only screen and (max-width: 900px) {
  .FacebookPosts {
    max-height: 100%;
  }
}
