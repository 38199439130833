.LogoHolder {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  display: flex;
  width: 100%;
}

.LogoLineLeft {
  width: 10%;
  margin-top: 4.5rem;
  border-top: 2px solid #f0273a;
}

.LogoLineRight {
  flex: 1;
  margin-top: 7rem;
  border-top: 2px solid #f0273a;
}

.Logo {
  height: 11rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Logo img {
  width: 11rem;
}

/* Mobile logo holder */
.LogoLineLeftMobile {
  width: 5%;
  margin-top: -1rem;
  border-top: 2px solid #f0273a;
}

.LogoLineRightMobile {
  position: relative;
  flex: 1;
  margin-top: 2rem;
  border-top: 2px solid #f0273a;
}
.LogoMobile {
  display: flex;
  justify-content: center;
  align-items: center;
}

.LogoMobile img {
  max-width: 6rem;
}

@media only screen and (max-width: 600px) {
  .LogoMobile img {
    max-width: 5rem;
  }
}
