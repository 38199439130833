.Article {
  margin-bottom: 3rem;
}

.Title {
  font-size: 3rem;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 1000px) {
  .Title {
    margin-top: 1rem;
  }
}

@media only screen and (max-width: 600px) {
  .Title {
    font-size: 2.5rem;
  }
}

.ProductFilter > button {
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  padding: 1rem;
  margin-bottom: 5px;
  margin-right: 5px;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  color: grey;
  background-color: #ffffff;
  cursor: pointer;
}

.ProductFilter > button:hover {
  border: 1px solid transparent;
  color: #ffffff;
  background-color: #f42434;
}
.ProductFilter > button:focus {
  outline: none;
}
.ProductFilter > button:active {
  transform: translateY(2px);
}

.ProductList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.ProductWrapper {
  position: relative;
  width: 26rem;
  height: 26rem;
  margin: 1rem;
  cursor: pointer;
  transition: all 0.2s ease-in;
}

.Product {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.ProductOverlay {
  display: none;
}

.ProductWrapper:hover .ProductOverlay {
  display: block;
}

.ProductLayer {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.ProductOnHover {
  background-color: #f42434;
  opacity: 0.5;
}

.ProductName {
  font-size: 2.2rem;
  font-weight: bold;
  text-transform: capitalize;
  text-align: center;
  color: white;
}
