.BrickContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.BrickContent {
  max-width: 105rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ProductPhotos {
  display: flex;
  flex-direction: row;
}

.MainPhoto {
  position: relative;
  flex: 4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MainPhoto img {
  width: 90%;
  padding: 5px;
}

.MainPhoto i {
  position: absolute;
  font-size: 4rem;
  color: #ffffff;
  cursor: pointer;
}

.MainPhoto i:hover {
  color: #dddddd;
}

.MainPhoto i:active {
  transform: translateY(2px);
}

.MainPhoto > .LeftIcon {
  left: 8%;
}

.MainPhoto > .RightIcon {
  right: 8%;
}

.SidePhotos {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: auto;
}

.SidePhotos img {
  width: 80%;
  padding: 5px 0;
  cursor: pointer;
}

.SidePhotos img:hover {
  opacity: 0.7;
}

img.SelectedPhoto {
  opacity: 0.4;
}

img.SelectedPhoto:hover {
  opacity: 0.4;
}

/* UpperLayer for selected image not used yet */
/* .UpperLayer {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #f42434;
  opacity: 0.4;
} */

.ProductDetails {
  width: 100%;
  margin-top: 3rem;

  display: flex;
  flex-direction: row;
}

.ProductIntro {
  flex: 3;
  padding: 0 1rem;

  display: flex;
  flex-direction: column;
}

.ProductSpecs {
  flex: 1;
  padding: 0 1rem;
}

.ProductTitle {
  font-size: 3rem;
  text-transform: capitalize;
}

.ProductCode p {
  font-size: 1.8rem;
}

.BackToBricks {
  cursor: pointer;
}

.BackToBricks:hover {
  color: #f42434;
}

@media only screen and (max-width: 600px) {
  .ProductPhotos {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .SidePhotos {
    width: 90%;
    height: auto !important;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .SidePhotos img {
    width: 25%;
    padding: 5px;
  }

  .ProductDetails {
    flex-direction: column;
  }

  .ProductIntro {
    margin-bottom: 2rem;
  }
}
