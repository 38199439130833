body {
  margin: 0;
  font-family: "Roboto", "Quicksand", sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.6;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:
    source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
  margin: 0;
  padding: 0;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; /* 1rem = 10px */
}

a {
  color: inherit;
  text-decoration: none;
}

li {
  margin-bottom: 0.5rem;
  list-style-position: inside;
}
