.Menu {
  min-width: 40%;
  height: 100%;
  position: fixed;
  top: 0;
  left: -100%;
  z-index: 3000;
  background-color: #ffffff;
  border: 1px solid #ccc;
  box-shadow: 3px 0px 5px 0px rgba(0, 0, 0, 0.4);
  transition: all 0.6s ease-in-out;
}

@media only screen and (max-width: 600px) {
  .Menu {
    min-width: 60%;
  }
}

.VisibleMenu {
  left: 0;
}

.MenuHeader {
  height: 7rem;
  border-bottom: 1px solid #efefef;
  cursor: pointer;

  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.MenuClose {
  font-size: 3rem;
  padding-right: 2rem;
  color: grey;
  cursor: pointer;
}

.MenuClose:hover {
  color: #f42434;
}

.MenuNav {
  display: flex;
  flex-direction: column;
}

.MenuNav > .MenuLink {
  font-size: 1.6rem;
  padding: 1rem;
  margin-left: 2rem;
  color: #818181;
  border-bottom: 1px solid #efefef;
  cursor: pointer;
}

.MenuNav > .MenuLink:hover {
  color: #ffffff;
  background-color: #f42434;
}
